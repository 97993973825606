import {useDataTracking} from '#imports';
import type {TranslationAction} from '~/config/TrackingEvents';
import {type EditionOpenedEntryPoint, type EditionOpenedWorkflow, type EditionOpenedSection, TrackingEvents} from '~/config/TrackingEvents';
import type {Profile} from '~/types/domain/Profile';

export function useProfileTracking() {
    const {track} = useDataTracking();

    function trackProfileEdition(section: EditionOpenedSection, entryPoint: EditionOpenedEntryPoint, workflow: EditionOpenedWorkflow) {
        track(TrackingEvents.PROFILE_EDITION_OPENED, {
            profile_section: section,
            entry_point: entryPoint,
            workflow,
        });
    }

    function trackProfileTranslated(profile: Profile, section: string, outputLanguage: string, action: TranslationAction) {
        track(TrackingEvents.TRANSLATION, {
            profile_section: section,
            input_language: profile.dominantLanguageDetected,
            output_language: outputLanguage,
            action,
            freelancer_account_id: profile.accountId,
        });
    }

    function compareCopiedTextWithFreelancerName(freelancerName: string) {
        if (import.meta.client) {
            const getSelection = window.getSelection || document.getSelection;
            const selection = !getSelection ? '' : getSelection()?.toString() || '';
            if (!!selection && freelancerName.includes(selection)) {
                track('freelancer_name_selection_in_profile_page');
            }
        }
    }

    return {
        trackProfileEdition,
        trackProfileTranslated,
        compareCopiedTextWithFreelancerName,
    };
}
